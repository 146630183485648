.LoginComponent {
  position: relative;
  display: grid;
  place-items: center;

  $background-color: #eee;

  &-modal {
    line-height: normal;

    & .modal-content {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    &-header {
      font-size: large;
      background: var(--app-default);
      color: #fff;
      border-radius: 5px 5px 0 0;
      padding: 5px 10px;
    }

    &-body {
      background: $background-color;
    }
  }

  &-modal-body &-header {
    display: none;
  }

  &-modal-body &-container {
    border: none;
    border-radius: 0;
  }

  &-container {
    border: 2px solid var(--app-default);
    border-radius: 10px 10px 0 0;
    width: 100%;
    max-width: 600px;
    background: $background-color;
  }

  &-header {
    background: var(--app-default);
    color: #fff;
    padding: 10px;
    font-size: larger;
    font-weight: bold;
  }

  &-message {
    font-size: 15px;
    color: var(--app-default);
    margin-bottom: 10px;
    text-align: center;

    &-warning {
      background: var(--app-negative);
      color: #fff;
      padding: 5px;
    }
  }

  &-content {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 10px;

    &-main {
      position: relative;
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }

  &-google {
    &-button {
      border: none;
      background: none;
      max-width: 300px;
    }

    &-image {
      max-width: 100%;
      height: auto;
    }
  }

  &-logging-in {
    max-width: 300px;

    &-logo {
      width: 100%;
      height: auto;
    }
  }

  &-bounce-gentle {
    -webkit-animation: LoginComponent-bounce-gentle 0.5s infinite linear;
    animation: LoginComponent-bounce-gentle 0.5s infinite linear;
  }

  @keyframes LoginComponent-bounce-gentle {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-0.1em);
    }
    70% {
      transform: translateY(0.2em);
    }
    100% {
      transform: translateY(0);
    }
  }
}
