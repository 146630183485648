.MyOptionsComponent {
  position: relative;
  display: flex;
  margin: 0 auto;
  gap: 20px;
  flex-wrap: wrap;
  flex: 1 1 auto;

  &-type {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 0 auto;
    border-radius: 10px 10px 0 0;

    &-title {
      display: grid;
      justify-content: center;
      background: #2f4f4f;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      padding: 5px 0;
      border-radius: 10px 10px 0 0;
    }

    &-content {
      display: flex;
      flex-direction: column;
      border: 2px solid var(--app-default);
    }
  }

  &-none {
    width: 100%;
    border-radius: 10px 10px 0 0;
    border: 2px solid var(--app-default);

    &-title {
      background-color: var(--app-default);
      font-size: 20px;
    }

    &-message {
      padding: 20px;
    }

    &-action {
      background-color: var(--app-default);
      color: #fff;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      padding: 10px;
    }

    &-example {
      &-message {
        padding: 10px;
      }

      &-try {
        padding-bottom: 5px;
      }

      &-stock {
        font-weight: bold;
        text-decoration: none;

        & > span {
          color: #000;
        }
      }

      &-steps {
        display: grid;
        justify-content: center;
        text-align: left;
      }
    }
  }
}
