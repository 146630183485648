.App {
  text-align: center;

  &-nav {
    background: var(--app-default);
    padding: 10px;
    border-bottom: 10px solid #fff;

    &-name {
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;

      &:hover {
        text-shadow: var(--app-text-shadow);
      }
    }

    &-menu {
      margin-left: 10px;
      display: grid;
      padding: 0;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      background-color: #fff;

      &:hover {
        background-color: var(--app-light);
      }

      &-toggle {
        border: none;
        padding: 0;
        display: grid;
        align-items: center;
        justify-content: center;

        &::after {
          display: none;
        }

        &-icon {
          display: grid;
          align-items: center;
          justify-content: center;
          font-size: large;
        }

        &-initials {
          font-weight: bold;
          font-size: large;
        }
      }

      &-dropdown {
        background-color: var(--app-default);
        color: #fff;

        &-item {
          display: grid;
          grid-template-columns: auto 1fr;
          justify-content: center;
          align-items: center;
          color: #fff;

          &:hover {
            background-color: var(--app-light);
            color: var(--app-default);
          }

          &-icon {
            --size: 40px;
            text-align: center;
            display: grid;
            align-items: center;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  &-content {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
