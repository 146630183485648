.OptionsPanelComponent {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-self: center;

  &-error {
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin: 10px;
    border-radius: 10px 10px 0 0;
    border: 2px solid var(--app-neutral);

    &-symbol {
      border-radius: 7px 7px 0 0;
      background-color: var(--app-neutral);
      color: white;
      font-weight: bold;
      padding: 5px;
      text-transform: uppercase;
    }

    &-message {
      min-height: 100px;
      display: grid;
      align-items: center;
    }
  }

  &-placeholder {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin: 10px;
    border-radius: 10px 10px 0 0;
    border: 2px solid var(--app-neutral);
    min-width: 300px;

    &-symbol {
      border-radius: 7px 7px 0 0;
      background-color: var(--app-neutral);
      color: white;
      font-weight: bold;
      padding: 5px;
      text-transform: uppercase;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-stock {
    display: flex;
    background: var(--app-neutral);
    color: #fff;
    flex-direction: column;
    padding: 5px;
    border-radius: 10px 10px 0 0;
    margin-top: 5px;

    &-not-standalone {
      box-shadow: var(--app-box-shadow-above);
    }

    &-indicator {
      &-plus {
        background: var(--app-positive);
      }

      &-minus {
        background: var(--app-negative);
      }
    }

    &-description {
      position: relative;
      display: grid;
      justify-content: center;
      color: #ccc;
    }

    &-details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10%;
      justify-content: center;
      font-weight: bold;
    }

    &-symbol {
      text-align: left;
      text-decoration: none;

      &-text {
        color: #fff;

        &:hover {
          text-shadow: var(--app-text-shadow);
        }
      }
    }

    &-price {
      display: flex;
      gap: 5px;
      justify-self: right;
    }
  }

  &-options {
    background: #fff;

    &-title {
      position: relative;
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: left;
      align-items: center;
      color: #fff;
      background: #2f4f4f;
      padding: 10px;
      font-size: larger;

      &-title {
        text-align: left;
      }

      &-follow {
        text-align: right;
      }
    }
  }

  &-option {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eee;

    &-column-title {
      font-size: x-small;
      color: #aaa;
    }

    &-expiration {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 5px 0 5px;

      &-date {
        text-align: left;
        font-weight: bold;
      }

      &-days {
        text-align: right;
        font-size: smaller;
        color: #999;
      }
    }

    &-details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 0 10px 0 10px;
      justify-items: center;
      text-align: center;
    }

    &-strike {
      justify-self: left;

      &-price {
        font-weight: bold;
      }

      &-percent {
        font-size: smaller;
        color: #999;
      }
    }

    &-last {
      &-price {
        font-weight: bold;
      }

      &-percent {
        font-size: smaller;
        color: #999;
      }
    }

    &-probability {
      justify-self: right;

      &-percent {
        font-weight: bold;
      }
    }
  }
}
