@mixin themeVars() {
  --app-default: #2f4f4f;
  --app-warn: #4f2f2f;
  --app-light: #77f0f0;
  --app-light-warn: #f07777;
  --app-positive: #008080;
  --app-negative: #cd5c5c;
  --app-neutral: #666;
  --app-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4), 0px 2px 2px rgba(0, 0, 0, 0.4), 0px 4px 4px rgba(0, 0, 0, 0.4),
    0px 8px 8px rgba(0, 0, 0, 0.4);
  --app-box-shadow-above: 0px -1px 1px rgba(0, 0, 0, 0.4), 0px -2px 2px rgba(0, 0, 0, 0.4),
    0px -4px 4px rgba(0, 0, 0, 0.4);
  --app-text-shadow: 0 -1px 3px rgba(0, 0, 0, 0.6);
}
