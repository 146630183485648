.AppAlert {
  position: absolute;
  right: 10px;
  z-index: 1000;
  background: var(--app-default);
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--app-light);
  box-shadow: var(--app-box-shadow);
  padding: 10px;
  color: #fff;
  font-size: larger;
  font-weight: 450;
  animation: 2s ease 0s AppAlert-animation, 2s ease 4s reverse AppAlert-animation;

  @keyframes AppAlert-animation {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
