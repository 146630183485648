// Using the name FllwButton instead of FollowButton since some AdBlockers block it.
// e.g. Fanboy Annoyances in ublock origin.
.FllwButton {
  &-button {
    background: none;
    border: none;

    &-icon {
      &-followed {
        color: #77f0f0;
        filter: drop-shadow(2px 0px 2px #000);

        &:hover {
          filter: none;
        }
      }

      &-not-followed {
        color: #ccc;

        &:hover {
          filter: drop-shadow(2px 0px 2px #000);
        }
      }
    }
  }

  &-tip {
    position: relative;

    &-popover {
      position: absolute;
      box-shadow: var(--app-box-shadow-above);
      border-radius: 5px;
      animation: 2s ease 0s normal forwards 1 FllwButton-fade-in;

      &-body {
        padding: 10px;
        background-color: var(--app-default);
        color: var(--app-light);
        border-radius: 5px;
        border: 1px solid var(--app-light);

        &-close {
          color: #ccc;

          &:hover {
            color: var(--app-light);
          }
        }
      }

      & .popover-arrow {
        &:after {
          border-bottom-color: var(--app-light);
        }
      }
    }
  }

  @keyframes FllwButton-fade-in {
    0% {
      opacity: 0;
    }
    33% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
