.TitleText {
    font-weight: bold;
    text-transform: lowercase;

    &>span:nth-child(odd) {
        color: var(--app-light);
    }

    &>span:nth-child(even) {
        color: #fff;
    }
}