.SearchComponent {
  &-pop {
    @media only screen and (min-width: 600px) {
      & {
        display: none;
      }
    }

    position: relative;
    margin-left: auto;

    &-button {
      background: none;
      border: none;
      width: 40px;
      height: 40px;
      padding: 0;
      color: var(--app-default);
      background-color: #fff;
      border-radius: 50%;

      &:hover {
        background-color: var(--app-light);
      }
    }

    &-popover {
      position: absolute;

      &-body {
        padding: 10px;
        background-color: var(--app-default);
        border-radius: 5px;
        border: 1px solid var(--app-light);
      }
    }

    .popover-arrow {
      &:after {
        border-bottom-color: var(--app-light);
      }
    }
  }

  &-inline {
    @media only screen and (max-width: 600px) {
      & {
        display: none;
      }
    }

    margin-left: auto;
  }

  &-content {
    position: relative;
    background-color: #fff;
    display: inline-flex;
    border-radius: 5px;

    &-text {
      border: none;
      background: none;
      outline: none;
      float: left;
      width: 150px;
      font-size: medium;
      padding: 5px;
      text-transform: uppercase;

      &::placeholder {
        text-transform: none;
        text-align: center;
      }
    }

    &-button {
      float: right;
      background: none;
      border: none;
      width: 40px;
      height: 40px;
      padding: 0;
      color: #2f4f4f;
      border-left: 2px solid var(--app-default);
      border-radius: 0 5px 5px 0;
      box-shadow: -5px 0px 5px -3px var(--app-default);

      &:hover {
        background-color: var(--app-light);
      }
    }
  }
}
