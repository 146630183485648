.HomeComponent {
  margin-bottom: 20px;

  &-hero {
    $marginBottom: 20px;

    &-spacer {
      margin-bottom: $marginBottom;
    }

    position: absolute;
    left: 0;
    width: 100vw;
    margin-bottom: $marginBottom;
    background-image: url("../../assets/logo/logo.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgba($color: #000, $alpha: 0.8);
    color: #fff;
    padding: 10px 0;
    box-shadow: var(--app-box-shadow);

    &-content {
      display: grid;
      justify-content: center;

      &-text {
        text-align: left;
        font-size: 20px;
        line-height: 3rem;
        font-weight: 500;

        &-item {
          display: grid;
          align-items: center;
          grid-template-columns: 40px 1fr;

          &-icon {
            color: var(--app-light);
          }
        }
      }
    }

    &-action {
      &-button {
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        background: var(--app-light);
        color: var(--app-default);
        font-size: 25px;
        font-weight: 500;
        box-shadow: var(--app-box-shadow);

        &:hover {
          box-shadow: var(--app-box-shadow-above);
        }
      }
    }
  }

  &-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @media screen and (min-width: 1024px) {
      & {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &-card {
    padding: 10px;
    background-color: var(--app-default);
    color: #fff;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;

    &-icon {
      color: var(--app-light);
      display: grid;
      justify-content: center;
      font-size: 40px;
      padding-bottom: 2px;
    }

    &-title {
      font-weight: bold;
      font-size: 20px;
      border-bottom: 1px solid #fff;
      margin-bottom: 5px;
    }

    &-body {
      text-align: left;
    }

    &-warn {
      background-color: var(--app-warn);

      .HomeComponent-card-icon {
        color: var(--app-light-warn);
      }
    }
  }
}
